import request from "@/libs/utils/request";
import {admin_api} from "@/libs/constants/const";

export const getBusinessList=(provider:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}instanzes_biz?provider=${provider}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const addBusiness =(provider:string,business_id:string,business_name:string,):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}instanzes_biz`,
        method: 'post',
        data:{
            provider,
            business_id,
            business_name,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
