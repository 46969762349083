import request from "@/libs/utils/request";
import {QueryModel} from "@/libs/models";
import parse from "@/libs/utils/QueryParser";
import {front_api} from "@/libs/constants/const";
import {admin_api} from "@/libs/constants/const";

export const addGroup = (owner_id:string,use_for:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups`,
        method: 'post',
        data:{
            owner_id,
            use_for,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const updateGroup = (id:string,group:any):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${id}`,
        method: 'put',
        data:group
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const getGroup=(id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}groups/${id}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const getGroupByAdmin=(id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${id}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const getGroups=(owner_id:string,query?:QueryModel):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups${parse(query,`owner_id=${owner_id}`)}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const deleteGroup=(id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${id}`,
        method: 'delete',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const setWebhook=(group_id:string,url:string,token:string,enable_custom_fields,form_items):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}webhook/${group_id}`,
        method: 'post',
        data:{
            url,
            token,
            enable_custom_fields,
            form_items,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const deleteWebhook=(group_id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}webhook/${group_id}`,
        method: 'delete',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
//
// // 临时接口
// export const updateGroupTenantId = (id:string):Promise<any> => new Promise((resolve, reject) => {
//     request({
//         url: `groups_tenantid/${id}`,
//         method: 'put',
//     }).then((response: any) => {
//         resolve(response.data);
//     }).catch((e) => reject(e));
// });
