import request from "@/libs/utils/request";
import {admin_api} from "@/libs/constants/const";

export const getTokens = ():Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `authing_api/tokens?includeData=true`,
        method: 'get',
    }).then((response: any) => {
        resolve(response);
    }).catch((e) => reject(e));
});
export const getAuthToken = (tokenId:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `authing_unjwt_api/authing/tokens/${tokenId}?refresh=1`,
        method: 'get',
    }).then((response: any) => {
        resolve(response);
    }).catch((e) => reject(e));
});
export const getClients = ():Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `authing_api/clients`,
        method: 'get',
    }).then((response: any) => {
        resolve(response);
    }).catch((e) => reject(e));
});

export const get_fbcomment_auths_api = (account_id,provider='fbcomment'):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}authing/auths?account_id=${account_id}&provider=${provider}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});