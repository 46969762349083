import request from "@/libs/utils/request";
import {code_need_pay,code_need_renewal} from "../../config/customCode";
import {front_api} from "@/libs/constants/const";
import {admin_api} from "@/libs/constants/const";

export const getCare = ():Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}care_user`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const postCare=(group_id:string,name:string,avatar?:string,receive_able?:string,uid?:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/cares`,
        method: 'post',
        data:{
            uid,
            receive_able,
            name,
            avatar,
        }
    }).then((response: any) => {
        if(response.code===code_need_pay||response.code===code_need_renewal){
            reject(response)
        }
        resolve(response.data);
    }).catch((e) => reject(e));
});


export const putCare=(group_id:string,care_id:string,name:string,avatar?:string,receive_able?:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/cares/${care_id}`,
        method: 'put',
        data:{
            receive_able,
            name,
            avatar,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const putCareByUid=(group_id:string,uid:string,name:string,avatar?:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/cares_uid/${uid}`,
        method: 'put',
        data:{
            name,
            avatar,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});




export const saveCare=(uid:string,name:string,avatar?:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}care_user`,
        method: 'post',
        data:{
            uid,
            name,
            avatar:avatar
        }
    }).then((response: any) => {
        if(response.code===code_need_pay||response.code===code_need_renewal){
            reject(response)
        }
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const careBelong = (group_id:string,uid:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}belong/${group_id}/cares/${uid}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const addmail = (group_id:string,email:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}email/${group_id}/add`,
        method: 'post',
        data:{
            email
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const delmail = (group_id:string,email:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}email/${group_id}/del`,
        method: 'post',
        data:{
            email
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const replayNotice = (group_id:string,uid:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}replay_notice/${group_id}/${uid}`,
        method: 'post',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const postWelcomeWord = (group_id:string,care_id:string,{word,enabled}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/welcome_words/${care_id}`,
        method: 'post',
        data:{word,enabled}
    }).then((response: any) => {
        resolve(response.data.welcomeWord);
    }).catch((e) => reject(e));
});
