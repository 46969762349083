import request from "@/libs/utils/request";
import {QueryModel} from "@/libs/models";
import parse from "@/libs/utils/QueryParser";
import {code_need_pay,code_need_renewal} from "../../config/customCode";
import {front_api} from "@/libs/constants/const";
import {admin_api} from "@/libs/constants/const";

export const addConnect = (group_id:string,care_uid:string,uid:string,messenger_id:string|null=null):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}connects`,
        method: 'post',
        data:{
            group_id,
            care_uid,
            uid,
            messenger_id,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const getConnects=(group_id:string,uid:string,query?:QueryModel):Promise<any> => new Promise((resolve, reject) => {
   const str= parse(query,`group_id=${group_id}&uid=${uid}`)
    request({
        url: `${front_api}connects${str}`,
        method: 'get',
    }).then((response: any) => {
        if(response.code===code_need_pay||response.code===code_need_renewal){
            reject(response)
        }
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const getLatest=(group_id:string,sender_uid:string,receiver_uid:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}latestConnect?group_id=${group_id}&sender_uid=${sender_uid}&receiver_uid=${receiver_uid}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const postLatest=(group_id:string,care_uid:string,uid:string,provider:'whatsapp'|'messenger'|'topkee',phone_code:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}latestConnect`,
        method: 'post',
        data:{
            group_id,
            care_uid,
            uid,
            provider,
            phone_code,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const getAllinvitableusers=(connect_id:string,page:number,searchName:string|null=null):Promise<any> => new Promise((resolve, reject) => {
   let filter='';
   if(searchName){
       filter=`&filter[name]=${searchName}`
   }
    request({
        url: `${front_api}connects/${connect_id}/allinvitableusers?page[number]=${page}${filter}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const invite = (connect_id:string,uid:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}connects/${connect_id}/invite`,
        method: 'post',
        data:{
            uid,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const deleteInvite = (connect_id:string,care_uid:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}connects/${connect_id}/invite`,
        method: 'delete',
        data:{
            uid:care_uid,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const addUserTag = (group_id:string,connect_id:string,tag:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}usertag/${group_id}/tags/${connect_id}`,
        method: 'post',
        data:{
            tag,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const askBotRespond = (care_uid:string|null,connect_id:string,message_id:string|null,content:string|null):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}connects_bot_respond/${connect_id}`,
        method: 'post',
        data:{
            message_id,
            care_uid,
            content
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const deleteUserTag = (group_id:string,connect_id:string,tag:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}usertag/${group_id}/tags/${connect_id}`,
        method: 'delete',
        data:{
            tag,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const getUserTags = (group_id:string,connect_id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}usertag/${group_id}/tags/${connect_id}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const getUserLevel = (connect_id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}connects_auto_level/${connect_id}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const setUserLevel = (connect_id:string,auto_level:string|null=null, enable_assistant:number):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}connects_auto_level/${connect_id}`,
        method: 'put',
        data:{
            auto_level,
            enable_assistant,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const sessionTimeoutCheck = (connect_id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}session_timeout_check/${connect_id}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const openInnerConnect = (group_id:string,care_uid:string,care_uid_other:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}inner_connects`,
        method: 'post',
        data:{
            group_id,
            care_uid,
            care_uid_other,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

