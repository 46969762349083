<template>
  <div :class="['messagebox',isPC()?'':'messagebox_mobile']"  v-show="toggleWindows" ref="messagebox" :style="{height:messageboxH}">
    <template v-if="addMGuserVisible">
      <Inviteusers @onclose="addMGuserVisible=false"
          :connect_id="connect_id"
          :theme_color="theme_color"
      ></Inviteusers>
    </template>
    <template v-else>
      <div class="messagebox-header" v-if="role=='care'" >
        <div>
            <a-icon
              type="left"
              class="user-goback"
              v-show="broken"
              @click="showUserList"
            />
            <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
               <span style="font-weight: bolder;font-size: 24px;margin: 0 10px;">{{destination?destination.name:""}}</span>
               <a-button
                  size="small"
                  title="邀請其他工作人員"
                  @click="addMGuserVisible=true"
                  :style="{color:theme_color,borderColor:theme_color}"
              >
                <a-icon type="usergroup-add" theme="outlined" />
                 邀請
              </a-button>

            </div>
        </div>
      </div>

      <div :class="['messagebox-content',role!='care'?'messagebox-content-uncare':'']" ref="msgContent">
        <a-spin :spinning="spinning" class="content-spin">
          <template slot="indicator"  >
            <div :style="{color:theme_color}">
              <a-icon :style="{fontSize:'40px'}" type="loading-3-quarters" spin />
            </div>
          </template>
          <div  class="moreMsgs" @click="loadMoreMsgs">
            <div class="more-inner" :style="{backgroundColor:theme_color}">{{loadText}}</div>
          </div>

          <template
              v-for="(item, i) in msgList"
          >
            <div :key="i" v-if="item.type==='mgroup'" style="clear: both; ">
              <div style="display: inline-block; margin: 0 auto;max-width: 95%;padding: 5px 15px; background: rgba(0,0,0,0.4);color: #FFFFFF;border-radius: 15px;margin-top: 10px ">
                {{item.payload}}
              </div>
            </div>
            <div :key="i" v-else class="message-group" :style="{marginTop:'6px', float: (item.sender_uid==source.uid) ? 'right' : 'left' }">
              <h4 style="text-align: left; margin: 0;font-weight: bold;" v-if="item.type!=='system'&&(item.sender_uid!=source.uid)">
                {{item.sender?item.sender.name:''}}
                <span v-if="item.fbcomment" class="fbcomment_warp">
                  <el-popover
                      v-if="item.fbcomment.verb!=='remove'"
                      placement="top-start"
                      title="貼文"
                      width="500"
                      trigger="hover">
                    <i style="color: #409eff"  slot="reference" class="el-icon-postcard"></i>
                    <div slot>
                      <p>{{getPostContent(item)}}</p>
                      <a target="_blank" :href="getPostLink(item)"> <i class="el-icon-link"></i> 打開原文</a>
                      <a v-if="!item.fbcomment.private_msg&&item.fbcomment.private_able" @click="onPrivateRelpay(item)" style="margin-left: 20px" href="javascript:;"> <i class="el-icon-chat-dot-round"></i>發送私訊</a>
                      <span  style="margin-left: 20px;text-decoration: line-through;" v-else>此訊息不支援發送私訊</span>
                      <div v-if="item.fbcomment.private_msg">
                        私訊：{{item.fbcomment.private_msg}}
                      </div>
                    </div>
                  </el-popover>

                  <el-popover
                      v-else
                      placement="top-start"
                      content="評論已被管理員或建立人刪除"
                      width="500"
                      trigger="hover">
                          <i style="color: #f04134" class="el-icon-warning"  slot="reference"></i>
                       </el-popover>


                </span>

              </h4>

              <div v-if="item.type==='system'" class="recallMsg">{{item.payload}}</div>
              <a-dropdown
                  v-else
                  :trigger="['contextmenu']"
                  :style="{ float: (item.sender_uid==source.uid) ? 'right' : 'left' ,'textAlign': (item.sender_uid==source.uid) ? 'auto' : 'left' ,maxWidth:(item.type !== 'audio'?'48%':'fit-content')}"
                  :disabled="true"
              >
                <span style="user-select: none">

                    <div @click="showErr(item.details)" class="err" v-if="item.details"
                         :style="{ float: (item.sender_uid==source.uid) ? 'left' : 'right'}"
                    >
                      <i  class="el-icon-warning"></i>
                    </div>
                    <div v-else-if="item.send_state===0"
                         :style="{ float: (item.sender_uid==source.uid) ? 'left' : 'right'}"
                    >
                      <i  class="el-icon-loading"></i>
                    </div>
                    <!-- 图片消息 -->
                    <div
                        v-if="item.type === 'img'"
                        style="overflow: hidden"
                    >
                       <el-image
                           :key="item.id"
                           fit="scale-down"
                           :src="getImgThumbSrc(item)"
                           :preview-src-list="[JSON.parse(item.payload).url]"
                           class="img-style"
                       />
                    </div>
                    <!-- 文件card -->
                    <div
                        v-else-if="item.type === 'file'"
                        class="file-style"
                        :style="{ float: (item.sender_uid==source.uid) ? 'right' : 'left' }">
                      <a-card :body-style="{ padding: '0px' }">
                        <div style="padding: 14px">
                          <h2>{{$t("care.file")}}</h2>
                          <span>
                            <h3>{{JSON.parse(item.payload).name}}</h3>
                          </span>
                          <div class="bottom clearfix">
                            <span>{{ readablizeBytes(JSON.parse(item.payload).size) }}</span>
                            <a-button type="link"  @click="download(JSON.parse(item.payload))">{{$t("care.download")}}</a-button>
                          </div>
                        </div>
                      </a-card>
                    </div>
                    <div v-else-if="item.type === 'video'" >
                    <video
                        preload="metadata"
                        class="img-style"
                        :src="item.payload ? (JSON.parse(item.payload).url) : ''"
                        fit="object-fit"
                        controls >

                    </video>

                  </div>
                    <div v-else-if="item.type === 'audio'" >
                        <audio
                            :src="item.payload ? (JSON.parse(item.payload).url) : ''"
                            controls >
                        </audio>
                    </div>
                    <div v-else-if="item.type === 'template'" :style="{display:'inline-block',  position: 'relative', borderRadius:'25px',userSelect: 'text',padding: '20px 20px 40px 20px',marginBlockEnd:0,
                        backgroundColor: '#eee'
                        ,color: (item.sender_uid==source.uid) ? '#FFF':''}" >
                        <span style="color: #333;">{{getTplFirst(item)}}</span>
                        <span style="color: #108ee9;">{{JSON.parse(item.payload).text}}</span>
                        <span style="color: #333;">{{getTplLast(item)}}</span>
                      <div style="font-size: 12px;color: #888;position:absolute;bottom: 4px;">客戶超24時未回覆或尚未回應，客戶端顯示此範本訊息</div>
                    </div>

                    <!-- 聊天消息 -->
                    <p
                        :style="{userSelect: 'text',padding: '15px',marginBlockEnd:0,
                        backgroundColor: (item.sender_uid==source.uid) ? theme_color:''
                        ,color: (item.sender_uid==source.uid) ? '#FFF':''}"
                        v-else
                        v-html="renderTxt(item.payload)"

                    />
                   <div class="leadbox_form" v-if="item.ext&&item.ext.ext_type=='form'">
                    <div class="leadbox_line" v-for="field in (JSON.parse(item.ext.form_def)).fields" :key="field.field">
                      <div style="font-weight: bolder; ">{{field.label}}：</div>
                      <div>{{getLeadBoxFormFieldVal(item.sender_uid==destination.uid?field:null)}}</div>
                    </div>
                  </div>
                </span>
                <a-menu slot="overlay">
                  <a-menu-item key="1" @click="handleCommand(item)">{{$t("care.recall")}}</a-menu-item>
                </a-menu>
              </a-dropdown>
              <!-- 聊天时间 -->
              <div
                  v-if="item.type!=='system'"
                  class="time-style"
                  :style="{ 'text-align': (item.sender_uid==source.uid) ? 'right' : 'left' }"
              >
                <strong v-if="item.is_bot" style="color: dodgerblue;">{{$t('care.msg_content.is_bot')}}</strong>
                {{ renderTime(item.created_at) }}
                {{ (item.state==1) ?$t("care.readed"): $t("care.unread") }}
              </div>

              <div  v-if="item.sender_uid==destination.uid&&item.tips"
                    class="time-style"
                    :style="{ 'text-align': (item.sender_uid==source.uid) ? 'right' : 'left' ,maxWidth: '48%',padding:'15px',backgroundColor:'rgba(0,0,0,0.45)',color:'#FFF',position:'relative',marginTop: '15px'}"
              >
                <div style="position: absolute;height: 0;width: 0;top: -15px;border-left: 15px solid transparent;border-right: 15px solid transparent;border-bottom:15px solid rgba(0, 0, 0,0.45);"></div>
                <div style="display: flex;flex-direction: row;align-items: center;height: 60px;">
                  <strong style="margin-right: 10px;">{{$t('care.botform.bot_tip_title')}}</strong>
                  <el-button size="mini" type="success" icon="el-icon-edit" circle @click="message=item.tips"></el-button>
                  <el-button size="mini" type="warning" icon="el-icon-s-promotion" circle @click="sendText(item.tips)"></el-button>
                </div>
                <div>{{item.tips}}</div>
              </div>
            </div>

          </template>
        </a-spin>
      </div>

      <div :class="['messagebox-footer',focusin?'messagebox-input-focusin':'']">
        <template>
          <div :class="{'care_quick_reply':true,'care_quick_reply_hide':!show_care_quick_reply}">
            <div class="title">
              <span style="margin-right: 20px">快速回覆模板選擇</span>
              <a-dropdown v-if="cat_selected">
                <a :style="{'fontWeight': 'bolder','fontSize': 'larger','color':theme_color}" class="ant-dropdown-link" @click.prevent>
                  {{cat_selected.name}}
                  <i class="el-icon-arrow-down"></i>
                </a>
                <template #overlay>
                  <a-menu @click="onSelectQuickReplyCat">
                    <a-menu-item v-for="cat in quickReplyCats" :key="cat.id">{{cat.name}}</a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
            <div class="list">
              <div @click="onAddQuickReply" class="add" :style="{backgroundColor:theme_color}">
                <i class="el-icon-plus" style="font-size:40px "></i>
              </div>
              <div style="flex: 1">
                <div v-if="list_care_quick_reply_loading">
                  <a-skeleton />
                </div>
                <div v-else-if="quick_replys.length==0" >
                  <el-empty :image-size="50"></el-empty>
                </div>
                <div v-else class="listinner">
                  <div v-for="reply in quick_replys" :key="reply.id">
                    <p class="text"  @click="onChoiceReply(reply)" >{{reply.text}}</p>
                    <div class="name">{{reply.name}}</div>
                    <div>
                      <el-popconfirm
                          title="確定刪除嗎？"
                          @confirm="onDeleteQuickReply(reply)"
                      >
                        <i slot="reference" class="el-icon-delete" style="color: #f04134;margin-right: 20px; cursor: pointer;"></i>
                      </el-popconfirm>
                      <i @click="onEditQuickReply(reply)" class="el-icon-edit" :style="{'cursor': 'pointer','color':theme_color}"></i>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </template>
        <div class="footer-icon">
          <!-- 表情组件 -->
          <ChatEmoji :theme_color="theme_color"  v-on:selectEmoji="selectEmoji" :inp_message="message" :inited="inited" />
          <!-- 上传图片组件 -->
          <UpLoadImage
              @onImageMsg="onImageMsg"
              :rtmToken="rtmToken"
              :source="source"
              :destination="destination"
              :connect_id="connect_id"
              :group_id="group_id"
              :theme_color="theme_color"
              :mgid="mgid"
              :inited="inited"
          />
          <!-- 上传文件组件 -->
          <UpLoadFile
              v-if="destination&&destination.provider!=='fbcomment'"
              @onFileMsg="onFileMsg"
              :rtmToken="rtmToken"
              :source="source"
              :destination="destination"
              :connect_id="connect_id"
              :group_id="group_id"
              :theme_color="theme_color"
              :mgid="mgid"
              :inited="inited"
          />
          <AudioFile
              v-if="destination&&destination.provider!=='fbcomment'"
              @onFileMsg="onFileMsg"
              :rtmToken="rtmToken"
              :source="source"
              :destination="destination"
              :connect_id="connect_id"
              :group_id="group_id"
              :theme_color="theme_color"
              :messenger_id="messenger_id"
              :mgid="mgid"
              provider="topkee"
              :inited="inited"
          />
<!--          <template>-->
<!--            <a-icon v-if="!askBotRespond" @click="onAskBotRespond" :style="{fontSize: '1.5em',color:theme_color}" :component="botSvg"  />-->
<!--            <i v-else class="el-icon-loading" :style="{marginLeft:'8px', fontSize: '1.5em',color:theme_color}" />-->
<!--          </template>-->
          <i @click="onFocusin" class="el-icon-sort" :style="{marginLeft:'8px', fontSize: '1.3em',color:theme_color,cursor:'pointer'}"/>
<!--          <a-popover :content="$t('care.botform.auto_level_tip')" trigger="hover">-->
<!--          </a-popover>-->
          <a-switch v-if="!mgid&&init_auto_level" @change="changeAutoLevel" :style="{marginLeft:'8px'}" :checked-children="$t('care.changeautolevel.is')" :un-checked-children="$t('care.changeautolevel.no')" v-model:checked="auto_level" />

          <!--          <a-popover :content="$t('care.botform.enable_assistant_tip')" trigger="hover">-->
<!--          </a-popover>-->
          <a-switch v-if="(mgid||auto_level)&&init_auto_level" @change="changeEnableAssistant" :style="{marginLeft:'8px'}" :checked-children="$t('care.enable_assistant.on')" :un-checked-children="$t('care.enable_assistant.off')" v-model:checked="enable_assistant" />

          <a-switch v-if="bot_enable&&init_auto_level" disabled :style="{marginLeft:'8px'}" :checked-children="$t('care.bot_enable.on')" :un-checked-children="$t('care.bot_enable.off')" v-model:checked="bot_enable" />
          <svg
              @click="onSwitchQuickReply"
               :style="{width: '1.3em',fill:theme_color,cursor: 'pointer'} "
               class="icon"
               viewBox="0 0 1024 1024"
               xmlns="http://www.w3.org/2000/svg"
          ><path d="M512 128a384 384 0 1 1-111.146667 751.658667l-158.165333 28.544a42.666667 42.666667 0 0 1-50.005333-37.376l-0.256-4.992 1.237333-139.008A384 384 0 0 1 512 128z m0 85.333333a298.666667 298.666667 0 0 0-254.506667 455.082667l6.826667 10.581333 14.933333 22.016-1.066666 114.048 127.701333-23.04 19.626667 5.973334A298.666667 298.666667 0 1 0 512 213.333333z m14.506667 86.016c5.76 1.749333 9.557333 7.082667 7.68 12.373334 0 4.736-6.4 51.456-19.072 140.16 0 3.541333 0 3.541333 3.84 3.541333h139.008c9.514667 0 15.232 5.333333 15.232 14.208 0 3.541333-1.92 5.333333-3.84 7.082667-45.653333 63.872-159.957333 223.530667-171.392 241.237333-1.877333 3.541333-5.674667 5.333333-9.514667 7.125333-7.594667 1.749333-15.232-5.333333-13.312-14.208 5.717333-31.914667 20.906667-124.16 24.746667-138.368 0-4.565333 0-5.205333-3.584-5.290666H351.36a12.586667 12.586667 0 0 1-9.557333-16c0-1.749333 1.92-3.541333 3.84-5.290667 55.210667-79.829333 110.421333-157.866667 163.754666-237.738667 0-1.749333 1.92-1.749333 1.92-3.541333 3.84-5.290667 9.514667-7.082667 15.232-5.290667z"></path></svg>
        </div>
        <div class="fotter-send">
          <a-textarea
            :disabled="!inited"
            v-model="message"
            equired
            :placeholder="$t('care.msg')"
            class="sengTxt"
            style="resize: none"
            @keydown="myOnkeydown"
            ref="txtDom"
          />
          <div  class="tpls">
            <el-select @change="onMouseenterTpl(tpl_id)" v-show="tplOptions.length&&sessionTimeoutCheckResult.timeout" v-model="tpl_id" placeholder="请选择模板">
              <el-option
                  v-for="item in tplOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"

              >
              </el-option>
            </el-select>
            <a-icon :component="sendSvg" @click="onSendTextMsg" :style="{ color:!inited?'#999':theme_color+' !important'}"/>

          </div>
         </div>
      </div>
    </template>
    <el-dialog
        title="Failed to send"
        :visible.sync="dialogVisible"
        width="30%">
      <p>{{details}}</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">Ok</el-button>
      </span>
    </el-dialog>

    <el-dialog title="發送私訊"
               :visible.sync="privateFormVisible"
               width="500px"
    >
      <H3>在Messenger悄悄回覆此評論，發起只有你們兩人能看到的對話</H3>
      <el-form :model="private_msg.msg" :rules="private_msg_rules">
        <el-form-item label="" >
          <el-input v-model="private_msg.msg"
                    autocomplete="off"
                    type="textarea"
                    placeholder="私訊內容"
                    maxlength="400"
                    :rows="4"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="privateFormVisible = false">取 消</el-button>
        <el-button :loading="private_msg_loading" type="primary" @click="onSubmitPrivateMsg"> <i class="el-icon-s-promotion"></i></el-button>
      </div>
    </el-dialog>


    <el-dialog title="快速回覆模版"
               :visible.sync="care_quick_reply_visible"
               width="80%"
    >
      <el-form :model="quickReplyForm">
        <el-form-item label="模版名稱" >
          <el-input v-model="quickReplyForm.name" placeholder="模版名稱"></el-input>
        </el-form-item>
        <el-form-item label="快速回覆內容" >
          <el-input v-model="quickReplyForm.text"
                    autocomplete="off"
                    type="textarea"
                    placeholder="快速回覆內容"
                    :rows="4"
          ></el-input>
        </el-form-item>
        <el-form-item label="擴展資訊類型(註：表單只在website頻道有效)" >
          <div style="text-align: left;">
            <el-radio-group v-model="quickReplyForm.ext_type">
              <el-radio-button label="none">僅文本</el-radio-button>
              <el-radio-button label="form">表單</el-radio-button>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item  v-if="quickReplyForm.ext_type==='form'" label="表單定義" >
          <el-input v-model="quickReplyForm.form_def"
                    autocomplete="off"
                    type="textarea"
                    :rows="6"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="care_quick_reply_visible = false">取 消</el-button>
        <el-button :loading="save_care_quick_reply_loading" type="primary" @click="onSaveQuickReply">保 存</el-button>
      </div>
    </el-dialog>
    <TplCard v-if="tplCardVisible" :visible="tplCardVisible" :tpl="selectedTpl" />
  </div>
</template>

<script>
import ChatEmoji from "../chatEmoji/index.vue";
import emoji from "../../config/emoji";
import UpLoadImage from "../upLoadImage/index.vue";
import UpLoadFile from "../upLoadFile/index.vue";
import AudioFile from "../audioFile/index.vue";
import Inviteusers from "../inviteusers/index.vue";
import SendSvg from "../svgs/SendSvg.vue";
import "./index.less";
import { isString } from "lodash";
import {getMessageDetails, one2oneMessages, readMessages, saveMessage} from "@/libs/apis/message";
import clientMixin from "@/mixins/clientMixin";
import {dateFormat, isJSON} from "@/libs/utils/utils";
import {Image, message} from "ant-design-vue";
import {askBotRespond, getLatest, getUserLevel, sessionTimeoutCheck, setUserLevel} from "@/libs/apis/connect";
import {replayNotice} from "@/libs/apis/care";
import BadWords from "@/components/chat/BadWords";
import {getGroup} from "@/libs/apis/group";
import {msglog} from "@/libs/apis/sensitive";
import groupMixin from "@/mixins/groupMixin";
import BotSvg from "@/components/svgs/BotSvg";
import {getBots} from "@/libs/apis/bot";
import {getMessengerTpls} from "@/libs/apis/messenger";
import TplCard from "@/pages/admin/components/TplCard.vue";
import {private_relpay_api} from "@/libs/apis/fccommentapi";
import {
  deleteCareQuickReply,
  getCareQuickReplyCats,
  getCareQuickReplys, postCareQuickReply,
  updateCareQuickReply
} from "@/libs/apis/care_quick_reply";
import LocalStore from "@/libs/utils/LocalStore";
import {front_api} from "@/libs/constants/const";

// 表情base64数组
const emojiPathArr=[];
for (const em in emoji.obj) {
  const png=emoji.obj[em];
  emojiPathArr[png] =require(`../../../static/faces/${png}`)
}
export default{
	data(){
    let cat=null;
    const cat_str=localStorage?.getItem('care_qr_cat_selected')??null;
    if(cat_str){
      cat=JSON.parse(cat_str);
    }
		return {
      tplCardVisible:false,
      botSvg:BotSvg,
      sendSvg:SendSvg,
			message: "",
			// status: {
			// 	sending: "发送中",
			// 	sent: "已发送",
			// 	read: "已读",
			// },
			msgList: [],
      pageNumber:1,
      pageSize:10,
      hasMode:true,
      spinning:false,
      destination:null,
      addMGuserVisible:false,
      messageboxH:"100%",
      replyTimer:null,
      onSendedTimer:null,
      group:null,
      details:null,
      dialogVisible:false,
      askBotRespond:false,
      focusin:false,
      auto_level:null,
      enable_assistant:0,
      init_auto_level:false,
      bot_enable:false,
      privateFormVisible:false,
      private_msg_loading:false,
      private_msg:{
        msg:null,
      },
      private_msg_rules:{
        msg: [
          { required: true, message: '請輸入私訊內容', trigger: 'blur' },
          { min: 1, max: 400, message: '長度在 400個字元以內', trigger: 'blur' }
        ],
      },
      inited:true,
      privateRelpayRow:null,
      tpl_id:null,
      tplDic:{},
      tplOptions:[],
      sessionTimeoutCheckResult:{
        timeout:false,
        message_template:null,
        provider:'topkee'
      },


      show_care_quick_reply:false,
      care_quick_reply_visible:false,
      save_care_quick_reply_loading:false,
      list_care_quick_reply_loading:false,
      quickReplyCats:[],
      quick_replys:[],
      cat_name:null,
      cat_selected:cat,
      active_reply_id:null,
      cat_adding:false,
      edit_reply:null,
      quickReplyForm:{
        name:null,
        text:null,
        ext_type:'none',
        form_type:'leadbox',
        form_def:null,
      },
      reply:null,
    };
	},
  beforeMount() {

  },
  mounted(){
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let that=this;
    let u = navigator.userAgent;
    // let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    // if (isAndroid) {
    //   //这个是安卓操作系统
    //   alert('android')
    // }
    if (isIOS) {
      // alert('ios')
      this.messageboxH=(window.innerHeight - 50)+"px";
    }

    this.onChangeDestination();
    const client=this.getClient();
    const listener=async function (message, peerId) {
      console.log(message, peerId)

      if(peerId==that.destination.uid){
        that.sessionTimeoutCheckResult.timeout=false;
      }
      let msg=null;
      if(message.messageType==='IMAGE'){
        that.clearTimer();
        // 图片消息
        const blob = await client.downloadMedia(message.mediaId);
        const payload = await that.imgPayLoad(blob);
        msg={
          sender_uid:that.source.uid,//发送人
          payload:payload,// 消息负载 根据消息类型来解析
          type:'agora_img',// 消息类型
          state:0,// 消息状态 0 未读 1已读
          sender:that.source,
          created_at:dateFormat(new Date(),"yyyy-MM-dd hh:mm:ss"),
        };

      }else if(isJSON(message.text)){
        msg= JSON.parse(message.text);
        if (msg.type=='agora_file'){
          that.parseAgoraFileMsg(msg);
        }
        if(msg.type!='action'){
          that.clearTimer();
        }
      }
      if(msg){// peerId==that.destination.uid&&
        if(msg.type!='action'){
          if(that.role!=='care'||peerId==that.destination.uid || (msg.mgid&&that.mgid && msg.mgid==that.mgid)){
            msg.sender?.username&&(msg.sender.name=msg.sender.username)
            msg.sender?.picture&&(msg.sender.avatar=msg.sender.picture)
            if(peerId==that.destination.uid){
              msg.state=1;
            }
            that.msgList.push(msg);
            that.readerReply();
            that.scollBottom();
            that.postMsg('receive_message','',message);
          }
        }
        if(peerId==that.destination.uid || (msg.mgid&&that.mgid && msg.mgid==that.mgid)) {
          setTimeout(()=>that.onReadMessages(),2000);
        }
      }
      if(msg&&msg.type==='action'&&msg.payload&&isJSON(msg.payload)){

        const payload= JSON.parse(msg.payload);
        console.log(payload)
        // if(payload.action==="care_change"){
        //   const newDestination=JSON.parse(payload.payload);
        //   if(newDestination.uid!==that.destination.uid&&that.destinationChange){
        //     that.destinationChange({...newDestination, is_care:true,});
        //   }
        //   that.destination=newDestination;
        // }
        switch (payload.action) {
          case 'mguser':

            // eslint-disable-next-line no-case-declarations
            const payloadinner=typeof payload.payload==='object'? payload.payload:JSON.parse(payload.payload);
            if(payloadinner.connect_id&&payloadinner.connect_id===that.connect_id){
              that.$emit('onMgid',payloadinner.mgid)
            }
            break;
          case 'read':

            if(peerId==that.destination.uid){
              that.reFlashUserMessageState()
            }
            break;
        }
      }
      that.$emit('onMessageFromOther',msg,peerId)
    };
    const funcs=client.listeners('MessageFromPeer');
    if(funcs.length) client?.off('MessageFromPeer',funcs.pop() );
    client.on('MessageFromPeer',listener );
    if(!this.group){
      getGroup(this.group_id).then(group=>{
        this.group=group;
        if(this.group&&this.group.tenant_id) {
          BadWords.getInstance(this.group.tenant_id);
        }
      });
    }
    this.initAutoLevel();
    setInterval(function () {
      that.paddingBotTips();
    },4*1000);
    setInterval(function () {
      that.queryMessageStatusRegularly()
    },60*1000);
    this.sessionCheck();

	},
	computed: {
		// 控制聊天框
		toggleWindows(){
			return true;
		},
    loadText(){
      return this.hasMode?this.$t("care.loadMore"):this.$t("care.noMore");
    },
    messenger_id(){
      return this.destination?this.destination.messenger_id:'';
    },
    selectedTpl(){
      if(this.tpl_id&&this.tpl_id in this.tplDic){
        return this.tplDic[this.tpl_id].data;
      }else{
        return  {};
      }
    },

	},
  watch:{
    destination2(){

      if(this.destination&&this.destination.uid==this.destination2.uid) return;
      this.inited=false;
      this.msgList=[];
      this.pageNumber=1;
      this.hasMode=true;
      this.addMGuserVisible=false;
      this.init_auto_level=false;
      this.$nextTick(async ()=>{
        this.onChangeDestination();

      });
    }
    ,
    'mgid'(val){
      if(val)
      {
        this.auto_level='artificial_assistance';
      }
    }
  },
	props: [
		"broken", // 是否适应移动端
		"showUserList",
		"hideUserList",
    "destinationChange",
    "source",
    "destination2",
    "rtmToken",
    "group_id",
    "time_limit",
    "connect_id",
    "mgid",
    "role",
    "theme_color",
	],
	methods: {
    getLeadBoxFormFieldVal(field){
      if(field){
        if(field.componentProps?.options){
          if(['Select','RadioGroup',].indexOf(field.component)>-1){
            const valueField= field.valueField;
            const find= field.componentProps.options.find(item=>item.value==valueField);
            if(find){
              return find.label;
            }
          }else if(['CheckboxGroup',].indexOf(field.component)>-1){
            const valueField= field.valueField??[];
            const filters= field.componentProps.options.filter(item=>valueField.indexOf(item.value)>-1);
            return filters.map(item=>item.label).join(',');
          }
        }

        return field.valueField;
      }
      return null;
    },
    async onChoiceReply(reply){
      this.message=reply.text;
      this.reply=reply;
    },
    async onEditQuickReply(reply){
      this.edit_reply=reply;
      this.quickReplyForm={
        ...this.edit_reply,
      };
      this.care_quick_reply_visible=true;
    },
    async onDeleteQuickReply({id}){
      this.edit_reply=null;
      await deleteCareQuickReply(this.group_id,id);
      this.loadCareQuickReplys();
    },
    cancelSaveQuickReply(){
      this.quickReplyForm.name=null;
      this.quickReplyForm.text=null;
      this.quickReplyForm.ext_type='none';
      this.quickReplyForm.form_def=null;
      this.edit_reply=null;
    },
    async onSaveQuickReply(){
      if(!this.quickReplyForm.text){
        this.$message.warning('請填寫快捷訊息文本')
        return;
      }
      if(this.cat_selected===null){
        this.$message.warning('請新增類別');
        this.cat_adding=true;
        return;
      }
      if(!this.quickReplyForm.name){
        this.quickReplyForm.name=this.quickReplyForm.text;
      }
      if(this.quickReplyForm.ext_type==='form'){
        let form_def=null;
        try {
          form_def=JSON.parse(this.quickReplyForm.form_def);
        }catch (e) {
          form_def=null;
        }
        if(!form_def){
          this.$message.warning('請正確填寫表單定義');
          return;
        }
      }
      this.save_care_quick_reply_loading=true;
      if(this.edit_reply){
        await updateCareQuickReply(this.group_id,this.edit_reply.id,{
          name:this.quickReplyForm.name,
          text:this.quickReplyForm.text,
          ext_type:this.quickReplyForm.ext_type,
          form_type:this.quickReplyForm.form_type,
          form_def:this.quickReplyForm.form_def,
        });
        this.edit_reply=null;
        this.$message.success('已更新')
      }else{
        await postCareQuickReply(this.group_id,{
          ...this.quickReplyForm,
          cat_id:this.cat_selected.id,
          ext_type:this.quickReplyForm.ext_type,
          form_type:this.quickReplyForm.form_type,
          form_def:this.quickReplyForm.form_def,
        });

      }
      this.save_care_quick_reply_loading=false;
      this.care_quick_reply_visible=false;
      this.loadCareQuickReplys();
      this.quickReplyForm.name=null;
      this.quickReplyForm.text=null;
      this.quickReplyForm.ext_type='none';
      this.quickReplyForm.form_def=null;
    },
    onAddQuickReply(){
      if(!this.cat_selected){
        this.$message.warning("请先去管理后台创建分类")
        return;
      }
      this.edit_reply=null;
      this.care_quick_reply_visible=true;
    },
    async  onSelectQuickReplyCat({key}){
      this.cat_selected=this.quickReplyCats.find(item=>item.id===key);
      localStorage?.setItem('care_qr_cat_selected',JSON.stringify(this.cat_selected))
      this.loadCareQuickReplys()
    },
    async loadCareQuickReplys(){
      if(this.cat_selected){
        this.list_care_quick_reply_loading=true;
        const {data}=  await getCareQuickReplys(this.group_id,this.cat_selected.id);
        this.quick_replys=data;
        this.list_care_quick_reply_loading=false;

      }else{
        this.quick_replys=[];
      }

    },
    async onSwitchQuickReply(){
      let that=this;
      if(!this.show_care_quick_reply){
          if(this.quickReplyCats.length===0){
            const {data}=await getCareQuickReplyCats(this.group_id);
            this.quickReplyCats=data;
            if(!this.cat_selected&&data.length){
              this.cat_selected=data[0];
              localStorage?.setItem('care_qr_cat_selected',JSON.stringify(this.cat_selected))
            }
          }

         setTimeout(function () {
           that.loadCareQuickReplys()
         },600);
      }else{
        setTimeout(function () {
          that.quick_replys=[];
        },600);

      }
      this.show_care_quick_reply=!this.show_care_quick_reply;

    },
    async onSubmitPrivateMsg(){
      if(this.private_msg.msg){
        if(this.private_msg.msg.length>400){
          this.$message.warning('長度在 400個字元以內');
          return;
        }
        if(this.privateRelpayRow){
          this.private_msg_loading=true;
           const id=this.privateRelpayRow.fbcomment.id
           try {
            const rs= await private_relpay_api(id,this.private_msg.msg);
            if(rs.success===1){
              const msgList=this.msgList;
              const index=msgList.findIndex(row=>row.id===this.privateRelpayRow.id);
              if(index>-1){
                msgList[index].fbcomment.private_msg=this.private_msg.msg;
                this.msgList=[].concat(msgList);
                const that=this;
                setTimeout(function () {
                  that.loadMoreMsgs(true)
                },20000);
              }
              this.privateFormVisible=false;
              this.private_msg.msg=null;
              this.privateRelpayRow=null;

            }else{
              this.$message.error(rs.reason);
            }
           }catch (e) {
             this.$message.error('回复失败。');
             throw e;
           }finally {
             this.private_msg_loading=false;
           }

        }



      }
    },
    onPrivateRelpay(item){
      this.privateRelpayRow=item;
      this.privateFormVisible=true;
    },
    getPostContent(item){
      if(item.fbcomment?.data){
        return JSON.parse(item.fbcomment.data).post_name
      }else{
        return '';
      }
    },
    getPostLink(item){
      if(item.fbcomment?.data){
        const post_id= JSON.parse(item.fbcomment.data).post_id
        return  `https://www.facebook.com/${post_id}`;
      }else{
        return null;
      }
    },
    getTplFirst(item){
      return JSON.parse(item.payload).template?.split('{{1}}')[0];
    },
    getTplLast(item){
      const arr=JSON.parse(item.payload).template?.split('{{1}}');
      arr.shift()
      return  arr.join('，');
    },
    async sessionCheck(){
      this.sessionTimeoutCheckResult=await sessionTimeoutCheck(this.connect_id);
      if(this.sessionTimeoutCheckResult.timeout&&this.sessionTimeoutCheckResult.message_template){
        const tpl=this.sessionTimeoutCheckResult.message_template;
        this.tplOptions=[
          {
            label:`${tpl.name}[${tpl.language}]`,
            value:tpl.id
          }
        ]
        this.tpl_id=tpl.id
        this.tplDic[this.tpl_id]=tpl

      }

      if(this.sessionTimeoutCheckResult.timeout){
        const tpls= await getMessengerTpls(this.messenger_id);
        this.tplOptions=tpls.map(item=>{
          this.tplDic[item.id]=item;
          return {value:item.id,label:`${item.name}[${item.language}]`}
        });
      }
    },
    getImgThumbSrc(item){
      if(item.payload){
        const payload=JSON.parse(item.payload);
        if(['jpg','jpeg','png'].indexOf(payload.url.split('.').reverse()[0])>-1){
          return payload.url+'?x-oss-process=image/resize,h_140';
        }
        return payload.url;

      }
      return '';
    },
    async paddingBotTips(){
      if(!this.enable_assistant){
        return;
      }
      // console.log('enable_assistant',this.enable_assistant)
      const cache_key=`care.setbot-${this.group_id}-${this.source.uid}`;
      let setbot=sessionStorage.getItem(cache_key)
      if(!setbot){
        const rs= await getBots(this.group_id,this.source.uid);
        if(rs['data'].length){
          sessionStorage.setItem(cache_key,1);
          setbot=1;
        }
      }
      if(setbot){
       let msgList=[]. concat(this.msgList).reverse();
        for (let i = 0; i < msgList.length; i++) {
          const msg=msgList[i];
          if(msg.type.toLowerCase()==='system')
          {
            continue;
          }else if(msg.sender_uid===this.source.uid) return;
          await this.paddingOneBotTips(msg)
        }
      }
    },
    async paddingOneBotTips(msg){
      if(msg.sender_uid==this.destination.uid&&msg.type.toLowerCase()=='text'&&!msg.tips&&!msg.tips_loading){

        msg.tips_loading=true;
        this.$set(this.msgList, this.msgList.findIndex(item=>item.id===msg.id), msg)
        const rs= await askBotRespond(this.source.uid,this.connect_id,msg.id,msg.payload);
        if(rs.reply)
        {
          const _index=this.msgList.findIndex(item=>item===msg)
          msg.tips=rs.reply;
          this.$set(this.msgList, _index, msg)
        }
      }

    },
    loadAutoLevel(rs) {
      if (rs.auto_level === 'artificial_assistance') {
        this.auto_level = true;
      } else {
        this.auto_level = false;
      }
      this.enable_assistant = !!rs.enable_assistant;
      this.bot_enable=rs.bot_enable;
    },
    async changeAutoLevel(){
      if(this.auto_level){
        this.$message.warning(this.$t('care.changeautolevel.tip'));
      }
      const rs=await setUserLevel(this.connect_id,this.auto_level?'artificial_assistance':null,this.enable_assistant?1:0);
      this.loadAutoLevel(rs);
    },
   async changeEnableAssistant(){
      let auto_level=this.auto_level;
      if(this.enable_assistant){
        auto_level='artificial_assistance';
      }
      const rs=await setUserLevel(this.connect_id,auto_level,this.enable_assistant?1:0);
      this.loadAutoLevel(rs);
    },
    async initAutoLevel(){
      try {

        const rs= await getUserLevel(this.connect_id);
        this.loadAutoLevel(rs);
        this.init_auto_level=true;
      }catch (e) {
       if(e.status){
         await this.$confirm('您的頁面已過期，請關閉目前頁面，重新前往YME登入', '系統警告', {
           confirmButtonText: '重新登陸',
           showCancelButton: false,
           showClose: false,
           distinguishCancelAndClose: false,
           closeOnPressEscape: false,
           closeOnClickModal: false,
           iconClass: 'el-icon-warning',
         });

         const care_origin=sessionStorage.getItem('care_origin')??'https://console.yme.top';
         window.location.href=care_origin+'/#/dashboard/home?logout=1';
       }
      }

    },
    onFocusin(){
     this.focusin=!this.focusin;
    },

    myOnkeydown(event){
      console.log(event)
      let that=this;
      if(event.keyCode === 13&&(!event.ctrlKey &&!event.shiftKey)){
        setTimeout(function () {
          // 移除这个换行符
          that.$data.message=that.$data.message.replace(/\n+$/, '');
          that.onSendTextMsg();
        },500);

      }
    },
    // async onAskBotRespond(){
    //   this.askBotRespond=true;
    //   try {
    //     const rs= await askBotRespond(this.connect_id);
    //     this.message=rs.reply;
    //     this.askBotRespond=false;
    //   }catch (e) {
    //     console.log(e);
    //     this.askBotRespond=false;
    //
    //   }
    //
    //
    //
    // },
    showErr(err){
      this.details=err;
      this.dialogVisible=true;

    },
    getWhatsappMedia(message_id,messenger_id,media_id){
      return `${front_api}third/whatsapp/media/${messenger_id}/${media_id}`;
    },
    clearTimer(){
      if(this.replyTimer!=null){
        clearTimeout(this.replyTimer);
        console.log("已停止timeout")
      }
    },
    isPC() {
      const userAgentInfo = navigator.userAgent;
      const Agents = ["Android", "iPhone",
        "SymbianOS", "Windows Phone",
        "iPad", "iPod"];
      let flag = true;
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    download(rs){
      let anchor = document.createElement("a");
      anchor.download = rs.name;
      anchor.href = rs.url+ '?response-content-type=application/octet-stream';
      anchor.target ="_blank";
      anchor.style.display = "none"; // just to be safe!
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    },
    async getConnectId(){
      const connect=await getLatest(this.group_id,this.source.uid,this.destination.uid);
      if(this.role!=='care'&&this.destinationChange&&connect.care&&connect.care.uid!==this.destination.uid){
        this.destinationChange({
          username:connect.care.name,
          picture:connect.care.avatar,
          uid:connect.care.uid,
          is_care:true,
        });
      }
      return connect.id;
    },
    // async parseAgoraFileMsg(msg){
    //   if(msg.type==='agora_file'&&isJSON(msg.payload)){
    //     const payload=JSON.parse(msg.payload);
    //     msg.payload=await this.loadAgoraFile(payload.mediaId);
    //     msg.fileSize=payload.size;
    //     msg.fileName=payload.name;
    //   }
    // },
    // async loadAgoraImg(mediaId){
    //   try {
    //     const client=this.getClient();
    //     // 图片消息
    //     const blob = await client.downloadMedia(mediaId);
    //     const payload = await this.imgPayLoad(blob);
    //     return payload;
    //   }catch (e) {
    //     return null;
    //   }
    //
    // },
    // async loadAgoraFile(mediaId){
    //   try {
    //     const client=this.getClient();
    //     const blob = await client.downloadMedia(mediaId);
    //     return  URL.createObjectURL(blob)
    //   }catch (e) {
    //     return null;
    //   }
    // },
    async onImageMsg(msg){
      // msg.payload= await this.loadAgoraImg(msg.payload);
      msg.hadParse=true;
      this.msgList.push(msg);
      this.replyTimeoutIntval();
      this.scollBottom();
      this.onSended();
    },
    async onFileMsg(msg){
      // msg.payload= await this.loadAgoraFile(msg.payload);
      // await this.parseAgoraFileMsg(msg);
      msg.hadParse=true;
      this.msgList.push(msg);
      this.replyTimeoutIntval();
      this.scollBottom();
      this.onSended();
    },
    async onChangeDestination(){
      this.destination=this.destination2
      // const connect_id=await this.getConnectId()
      // this.connect_id_last=connect_id;
      // // this.connect_id_last=this.connect_id
      await this.loadMoreMsgs()
      this.scollBottom();
      this.onReadMessages();
      // this.$emit('onMessageFromOther',null,this.destination.uid);
      this.readerReply();
      this.initAutoLevel();
      this.inited=true;
    },
    async onReadMessages(){
      await readMessages(this.connect_id,this.source.uid);
    },
    // 刷新用户发送的消息状态
    async reFlashUserMessageState(){
      const rs= await one2oneMessages(this.group_id,this.connect_id,{
        include:['sender'],
        page:{
          number:1,
          size:30,
        }
      });
      rs.data.map(item=>{
        const find=this.msgList.find(s=>s.id===item.id);
        if(find){
          find.send_state=item.send_state;
          find.details=item.details;
          find.state=item.state;
        }
      });

    },
    queryMessageStatusRegularly(){
      this.msgList.map(item=>{
        if(item.send_state===0&&item.details===null&&item.receiver_uid===this.destination.uid){
          this.loadMsgState(item);
        }
      });
    },
    loadMsgState(msg){
      if(msg.receiver_uid===this.destination.uid&&msg.send_state==0){
      const that=  this;
        setTimeout(async function () {
          const find= that.msgList.find(item=>item.id===msg.id);
          if(find){
            if(find.receiver_uid===that.destination.uid&&find.send_state==0){
              const details=await getMessageDetails(find.id);
              find.send_state=details.send_state;
              find.details=details.details;
              find.state=details.state;
            }
          }


        },5000);
      }

    },
    changeAllMsgState(){
      for (let i = 0; i <this.msgList.length ; i++) {
        this.msgList[i].state=1;
      }
    },
    readerReply(){

      const client=this.getClient();
      if(client){
        // 告诉对方消息已读
        const msg_reply = {
          sender_uid:this.source.uid,//发送人
          payload: JSON.stringify({
            'action': 'read',
            'payload': '',
          }),// 消息负载 根据消息类型来解析
          type: 'action',// 消息类型
          state: 0,// 消息状态 0 未读 1已读
          sender:this.source,
          mgid:this.mgid?this.mgid:null,
          created_at: dateFormat(new Date(), "yyyy-MM-dd hh:mm:ss"),
        }
        const message = {text: JSON.stringify(msg_reply)};
        client.sendMessageToPeer(message, this.destination.uid)
      }
    },
    // eslint-disable-next-line no-mixed-spaces-and-tabs
	  async	loadMoreMsgs(reload=false){
      if(reload===true){
        this.pageNumber=1;
        this.hasMode=true;
      }
      if(!this.hasMode) return;
      this.spinning=true;

      let that=this;
      const rs= await one2oneMessages(this.group_id,this.connect_id,{
        include:['sender','fbcomment','ext'],
        page:{
          number:this.pageNumber++,
          size:this.pageSize,
        }
      });
      this.spinning=false;
      if(!rs.data.length || rs.data[0].connect_id!==this.connect_id){
        this.hasMode = false;
        console.log("对话以切换，抛弃")
         return ;
      }
      this.hasMode=rs.data.length==this.pageSize;
      async function parseMsgs(data) {
        for (let i = data.length-1; i >0; i--) {
          if(data[i].hadParse||(['agora_img','agora_file'].indexOf(data[i].type)==-1)) continue;
          if(data[i].type=='agora_img'){
            data[i].payload=await that.loadAgoraImg(data[i].payload)
          }
          if(data[i].type=='agora_file'){
            await that.parseAgoraFileMsg(data[i]);
          }
          data[i].hadParse=true;
        }
      }
      let data= rs.data.reverse();

      if(reload===true){
        this.msgList=data;
      }else{
        this.msgList=data.concat(this.msgList);
      }

      parseMsgs(this.msgList);
		},
		// 右侧菜单
		rightMenus(){

		},
		menuClick(i){

		},
    async sendText(msg_txt,type='text',messengertpl_id=null,ext=null) {
      // 发送消息
      const client = this.getClient();
      if (client) {
        await this.checkAndRelogin();
        const msg = {
          sender_uid: this.source.uid,//发送人
          payload: msg_txt,// 消息负载 根据消息类型来解析
          type: type,// 消息类型
          state: 0,// 消息状态 0 未读 1已读
          sender: this.source,
          created_at: dateFormat(new Date(), "yyyy-MM-dd hh:mm:ss"),
          mgid: this.mgid ? this.mgid : null,
          ext:ext,
          connect_id: this.connect_id,
          group_id: this.group_id,
        }
        const message = {text: JSON.stringify(msg)};

       const send_rs=await saveMessage(this.source.uid,
            this.destination.uid,
            msg.payload,
            msg.state,
            msg.type,
            this.group_id,
            this.connect_id,
            this.messenger_id,
            messengertpl_id,
            ext
        );
        this.msgList.push(send_rs)
        this.loadMsgState(send_rs);
        client.sendMessageToPeer(message, this.destination.uid)
      }
      this.scollBottom();
      this.onSended();
    },
    onMouseenterTpl(tpl_id){
      this.tplCardVisible=false;
      this.$nextTick(function () {
        this.tplCardVisible=true;

      })
    },
		async onSendTextMsg(){

      if(!this.inited) return;
			if(this.$data.message == "" || this.$data.message == "\n"){
				this.$data.message = "";
				return;
			}
      const msg_txt=this.$data.message;
      // this.$data.message = "";

      if(!this.group){
        this.group=await this.getSetGroupByCache(this.group_id);
      }
      if(this.group&&this.group.tenant_id){
        const badWords=BadWords.getInstance(this.group.tenant_id);
        const { result, word } = badWords.filter(msg_txt);
        if (!result) {
          message.warning(`发送失败，您的消息含有敏感词'${word}'`);
          if(badWords.instanzeData?.id){
            msglog(badWords.instanzeData?.id,word,msg_txt);
          }
          return;
        }
      }
      // console.log(this.tpl_id,this.sessionTimeoutCheckResult)
      const ext=((this.reply?.ext_type??'none')!=='none')?this.reply:null;
      if(this.sessionTimeoutCheckResult?.timeout===true&&this.tpl_id!==null){
        const tplModel=this.tplDic[this.tpl_id];
        const tpl=JSON.parse(tplModel.data);
        const tpl_body= tpl.components.find(item=>item.type==='BODY');
        const payload={
          'text':msg_txt,
          'template':tpl_body.text,
          'template_original':tplModel.data,
        };
        if (/\n| {4}/.test(msg_txt)){
          this.$alert('WhatsApp範本訊息禁止含有回車換行符號和四個或以上連續空格, 請更正後再次發送', '格式異常,已取消發送',{type:'warning'})
          return ;
        }
        this.sendText(JSON.stringify(payload),'template',tplModel.id,ext);
      }else{
        this.sendText(msg_txt,'text',null,ext);
      }
      this.replyTimeoutIntval();
      this.$data.message = "";
      this.reply=null;

      // if(this.source.is_care){
      //   const msg= {
      //     sender_uid:this.source.uid,//发送人
      //     payload: JSON.stringify({
      //       'action': 'care_change',
      //       'payload': JSON.stringify(this.source),
      //     }),// 消息负载 根据消息类型来解析
      //     type:'action',// 消息类型
      //     state:1,// 消息状态 0 未读 1已读
      //     sender:this.source,
      //     created_at:dateFormat(new Date(),"yyyy-MM-dd hh:mm:ss"),
      //   }
      //   const message={text: JSON.stringify(msg)};
      //   client.sendMessageToPeer(message,this.destination.uid)
      // }

		},
    replyTimeoutIntval(){
      if(this.role!=='care'){
        if(this.replyTimer===null){
          let that=this;
          this.replyTimer=setTimeout(function (){
            replayNotice(that.group_id,that.source.uid).then((_data)=>{
              if(_data&&_data.res===1) {
                that.$message.success(this.$t("care.wait"));
              }else{
                that.$message.warning(this.$t("care.careOffline"));
              }
            })
          },that.time_limit*1000);
        }
      }
    },
		selectEmoji(v){
			this.$data.message = this.$data.message + v;
			this.$refs.txtDom.focus();
		},

		customEmoji(value){
			// return `<img src="../../../static/faces/${value}" style="width:20px"/>`;
      const src=emojiPathArr[value];
      return `<img src="${src}" style="width:20px"/>`.replace(/,/g, "FACE_SEPARATOR");
		},

		renderTxt(txt = ""){
			// let rnTxt = [];
			// let match = null;
			// const regex = /(\[.*?\])/g;
			// let start = 0;
			// let index = 0;
			// while((match = regex.exec(txt))){
			// 	index = match.index;
			// 	if(index > start){
			// 		rnTxt.push(txt.substring(start, index));
			// 	}
			// 	if(match[1] in emoji.obj){
			// 		const v = emoji.obj[match[1]];
			// 		rnTxt.push(this.customEmoji(v));
			// 	}
			// 	else{
			// 		rnTxt.push(match[1]);
			// 	}
			// 	start = index + match[1].length;
			// }
			// rnTxt.push(txt.substring(start, txt.length));
			return txt;
		},
		readablizeBytes(value){
      if(!value) return '未知大小';
			let s = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
			let e = Math.floor(Math.log(value) / Math.log(1024));
			return (value / Math.pow(1024, Math.floor(e))).toFixed(2) + " " + s[e];
		},

		// TODO 可以抽离到utils
		renderTime(time){
			if(isString(time)){
				return time;
			}
			const t = new Date(parseInt(time));
			var Y = t.getFullYear();
			var M =
        t.getMonth() + 1 < 10 ? "0" + (t.getMonth() + 1) : t.getMonth() + 1;
			var D = t.getDate() < 10 ? "0" + t.getDate() : t.getDate();
			var H = t.getHours() < 10 ? "0" + t.getHours() : t.getHours();
			var F = t.getMinutes() < 10 ? "0" + t.getMinutes() : t.getMinutes();
			var S = t.getSeconds() < 10 ? "0" + t.getSeconds() : t.getSeconds();
			return `${M}-${D} ${H}:${F}`;
		},

		scollBottom(){
			setTimeout(() => {
				const dom = this.$refs.msgContent;
				if(!dom) return;
				dom.scrollTop = dom.scrollHeight;
			}, 0);
		},
		handleCommand(item){

		},
    onSended(){
      console.log('onSended')
      let that=this;
      if(this.destination2?.provider!=='topkee'){
        clearTimeout(this.onSendedTimer);
        this.onSendedTimer= setTimeout(function () {
         that.loadMoreMsgs(true);
        },4000);
      }
    }

	},
	components: {
		ChatEmoji,
		UpLoadImage,
		UpLoadFile,
    SendSvg,
    Inviteusers,
    AudioFile,
    TplCard,
	},
  mixins:[clientMixin,groupMixin],
};
</script>

<style scoped lang="less">
@import "../../style/index.less";

.fbcomment_warp{
  text-align: left;
}
.byself {
  background-color: #eceff1;
  float: right;
}
.recallMsg {
  font-size: 12px;
  color: #aaa;
  width: 100%;
  text-align: center;
}
.custom-title {
  font-weight: 500;
}
.moreMsgs {
  height: 30px;
  line-height: 30px;
  .more-inner{
    min-width: 150px;
    background: @base;
    color: white;
    border-radius: 15px;
    cursor: pointer;
    display: inline-block;
    padding: 0px 20px;
    transition: all 0.5s linear;
  }
}
.status {
  display: inline;
  position: relative;
  top: 20px;
  font-size: 12px;
  left: -6px;
  color: #736c6c;
  float: left;
}
.unreadNum {
  float: left;
  width: 100%;
}
.icon-style {
  display: inline-block;
  background-color: #f04134;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  line-height: 1.5;
  text-align: center;
}
.emoji-style {
  width: 22px;
  float: left;
}
.img-style {
  max-width: 350px;
  width: 200px;
  height: 200px;
}
.time-style {
  clear: both;
  margin-left: 2px;
  margin-top: 3px;
  font-size: 12px;
  color: #888c98;
}
.file-style {
  width: 240px;
  margin: 2px 2px 2px 0;
  font-size: 13px;
  h2 {
    border-bottom: 1px solid #e0e0e0;
    font-weight: 300;
    text-align: left;
  }
  h3 {
    max-width: 100%;
    font-size: 15px;
    height: 20px;
    line-height: 20px;
    font-weight: 600;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    margin-bottom: 20px;
  }
  .bottom {
    span {
      color: #999999;
      text-align: left;
    }
  }
  a {
    color: #999999;
    float: right;
    text-decoration: none;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
}
.fotter-send{
  //background-color: #42b983;
  //position: relative;

  .tpls{
    min-height: 40px;
    margin-right: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .anticon{
      height: 100%;
      line-height: 100%;
      margin-left: 8px;
      font-size: 20px;
    }
  }
}
.messagebox-content{
  .content-spin{
    height: 100%;
  }
  &::-webkit-scrollbar { width: 0 !important }
}
.anticon-loading-3-quarters,.anticon-loading{
  background-color: rgba(0,0,0,0) !important;
}
.err{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 18px;
  cursor: pointer;
  color: deeppink;
}


/* 适用于 Firefox */
.hidden_scrollbar {
  scrollbar-width: none; /* Firefox */
}

/* 适用于 Chrome, Safari, Edge 等基于 WebKit 的浏览器 */
.hidden_scrollbar::-webkit-scrollbar {
  display: none;
}

.care_quick_reply{
  border: 1px solid #ccc;
  position: absolute;
  z-index: 2;
  height: 240px;
  top: 0;
  margin-top: -240px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  transition: all ease-in-out .5s;
  left: 0;
  .title{
    color: #666;
    font-size: small;
    padding: 5px 20px;
    text-align: left;
    border-bottom: 1px solid #efefef;
  }
  .list{
    flex: 1;
    padding:  20px 20px 0 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow-x: auto;
    white-space: nowrap;
    .listinner{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      &>div{
        cursor: pointer;
        width: 180px;
        height: 140px;
        border: 1px solid #e0e0e0;
        margin-right: 20px;
        padding: 5px 10px 0 10px;
        border-radius: 10px;
        text-align: left;
        position: relative;
        .text{
          white-space: pre-wrap;
          font-weight: bold;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 60px;
          -webkit-line-clamp: 3;
          text-overflow: ellipsis; /* 超出部分显示省略号 */
        }
        .name{
          position: absolute;
          left: 10px;
          bottom: 5px;
          font-size: smaller;
          color: #999;
          height: 1em;
          width: calc(100% - 20px);
          line-height: 1em;
          overflow: hidden;
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
        }
      }
    }
    .add{
      min-width: 140px;
      max-width: 140px;
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #e0e0e0;
      margin-right: 20px;
      border-radius: 10px;
      text-align: left;
      font-weight: bolder;
      font-size: large;
      cursor: pointer;
      color: white;

    }
  }


}
.leadbox_form{
  border: 1px solid #eceff1;
  margin-top: 5px;
  border-radius: 25px;
  padding: 20px 20px 30px 20px;
  font-size: 16px;
  box-shadow: 5px 1px 7px rgba(236,239,241,0.8);
  min-width:280px;

  .leadbox_line{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #e0e0e0;
  }
}

.care_quick_reply_hide{
  left: 100%;
  z-index: -1;
  width: 0;
}

</style>
