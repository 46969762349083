import request from "@/libs/utils/request";
import {front_api} from "@/libs/constants/const";

export const ossUpload=(file:File):Promise<any> => new Promise((resolve, reject) => {
    // 在这里进行一系列的校验
    const formData = new FormData();
    formData.append("file",file);
    return request({
        url: `${front_api}oss/upload`,
        method: 'post',
        data:formData
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const ossUploadByconnect=(connect_id:string|number,file:File,type:string|null=null):Promise<any> => new Promise((resolve, reject) => {
    // 在这里进行一系列的校验
    const formData = new FormData();
    formData.append("file",file);
    if(type) formData.append("type",type);
    return request({
        url: `${front_api}oss/upload_by_connect/${connect_id}`,
        method: 'post',
        data:formData
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});