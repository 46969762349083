import request from "@/libs/utils/request";
import {QueryModel} from "@/libs/models";
import parse from '@/libs/utils/QueryParser';
import {front_api} from "@/libs/constants/const";

export const getUserGreetings = (group_id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}usergreetings/${group_id}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const saveUserGreetings=(group_id:string,greeting:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}usergreetings/${group_id}`,
        method: 'post',
        data:{
            greeting,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const deleteUserGreetings=(group_id:string,id:string|number):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}usergreetings/${group_id}/${id}`,
        method: 'delete',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
