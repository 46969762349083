import request from "@/libs/utils/request";
import {front_api} from "@/libs/constants/const";

export const getFormItems = (group_id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}userform/${group_id}/items`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const getUserFormItems = (uid:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}userform/${uid}/content`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const postFormItems = (group_id:string,formItems):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}userform/${group_id}/items`,
        method: 'post',
        data:{
            items:formItems
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const postUserFormItems = (uid:string,userform):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}userform/${uid}/content`,
        method: 'post',
        data:{
            userform:userform
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});