<template>
  <el-dialog
      :title="$t('care.careform.tpl_setting')"
      :visible="visible"
      @close="onClose"
      append-to-body>
    <el-form :model="form"  :label-width="formLabelWidth" style="max-width: 800px">
      <el-alert
          style="margin-bottom: 30px;"
          type="success"
          :title="$t('care.careform.tpl_tips')"
          show-icon>
      </el-alert>
      <el-form-item :label="$t('care.careform.tpl')" prop="typeahead_tpl">
        <div class="formval">
          <el-input type="textarea" rows="6" v-model="form.typeahead_tpl" :placeholder='placeholder'></el-input>
        </div>
      </el-form-item>
      <el-form-item :label="$t('care.careform.tpl_review')">
        <div class="formval">
          <el-input disabled type="textarea" rows="6" v-model="tpl_review"></el-input>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onClose">{{$t('care.messenger.cancel')}}</el-button>
      <el-button :loading="addCareLoading" type="primary" @click="save">{{$t('care.messenger.sure')}}</el-button>
    </div>
  </el-dialog>


</template>

<script>

import {getTypeaheadTpl, postTypeaheadTpl} from "@/libs/apis/typeahead_tpl";

export default {
  name: "TypeaheadTplForm",
  props: [
    "typeaheadable_type",
    "visible",
    "typeaheadable_id",
    "group_id",
  ],
  components:{
  },
  data(){
    return {
      formLabelWidth:'120px',
      formItemLoading:false,
      form:{
        typeahead_tpl:'{{code}}'
      },
      spinning:false,
      addCareLoading:false,
      placeholder:'{{code}}',
    };
  },
  watch:{
  },
  mounted() {

    this.getTpl();
  },
  computed:{
    tpl_review(){
      const code='enquiryno:ZX046';
      return this.form.typeahead_tpl?.replace('{{code}}',code)
    },
  },
  methods:{
    async getTpl(){

      const tpl=await getTypeaheadTpl(this.$props.group_id,this.$props.typeaheadable_id,this.$props.typeaheadable_type);
      if(tpl){
        this.form.typeahead_tpl=tpl.typeahead_tpl
      }

    },
    async save(){
      if(!this.form.typeahead_tpl) {
        this.$message.warning(this.$t('care.messenger.messenger_add_parms'));
        return;
      }
      if( this.form.typeahead_tpl.indexOf('{{code}}') === -1){
        this.$message.warning('模板必須含有 {{code}}');
        return;
      }
      try {
            this.addCareLoading=true;

            await postTypeaheadTpl(this.$props.group_id,this.$props.typeaheadable_id,{
              typeaheadable_type:this.$props.typeaheadable_type,
              typeahead_tpl:this.form.typeahead_tpl
            })
            this.addCareLoading=false;
            this.$message.success('success')
            this.onClose();
      }catch (e) {
        this.addCareLoading=false;
        this.$message.error(e.message);
        throw e;
      }

    },
    onClose(){
      this.$emit("onClose");
    },
    onBeforeUploadImage(file) {
      return true;
    },
  },
}
</script>

<style scoped>

</style>