import request from "@/libs/utils/request";
import {admin_api} from "@/libs/constants/const";

export const postCareQuickReplyCat =(group_id,{name}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/care_quick_reply_cats`,
        method: 'post',
        params:{name}
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const getCareQuickReplyCats =(group_id):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/care_quick_reply_cats?page[size]=100`,
        method: 'get'
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const updateCareQuickReplyCat =(group_id,id,{name}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/care_quick_reply_cats/${id}`,
        method: 'put',
        data:{name}
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const deleteCareQuickReplyCat =(group_id,id):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/care_quick_reply_cats/${id}`,
        method: 'delete',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const getCareQuickReplys =(group_id,cat_id):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/care_quick_replys?page[size]=100&filter[cat_id]=${cat_id}`,
        method: 'get'
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const postCareQuickReply =(group_id,{name,text,cat_id, ext_type,form_type, form_def}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/care_quick_replys`,
        method: 'post',
        params:{name,text,cat_id, ext_type,form_type, form_def}
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const updateCareQuickReply =(group_id,id,{name,text,cat_id, ext_type,form_type, form_def}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/care_quick_replys/${id}`,
        method: 'put',
        params:{name,text,cat_id, ext_type,form_type, form_def}
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});export const deleteCareQuickReply =(group_id,id):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/care_quick_replys/${id}`,
        method: 'delete'
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});