import {QueryModel} from "@/libs/models";
import request from "@/libs/utils/request";
import parse from "@/libs/utils/QueryParser";
import {admin_api} from "@/libs/constants/const";

export const getTrustedDomains=(group_id:string,query?:QueryModel):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/trusted_domains${parse(query)}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const addTrustedDomain = (group_id,parms):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/trusted_domains`,
        method: 'post',
        data:parms
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});


export const updateTrustedDomain = (group_id,id,parms):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/trusted_domains/${id}`,
        method: 'put',
        data:parms
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const deleteTrustedDomain = (group_id,id):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/trusted_domains/${id}`,
        method: 'delete',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const enableTrustedDomain = (group_id,id,enable):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/trusted_domains/${id}/enable`,
        method: 'post',
        data:{
            enable
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});