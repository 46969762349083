import request from "@/libs/utils/request";
import {admin_api} from "@/libs/constants/const";

export const getMessengerCalls=(group_id:string,{call_type,ref_id}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/messenger_calls`,
        method: 'get',
        params:{call_type,ref_id}
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const postMessengerCall=(group_id:string,{call_type,ref_id,messenger_id,call_text,enabled,mobile_force}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}groups/${group_id}/messenger_calls`,
        method: 'post',
        data:{call_type,ref_id,messenger_id,call_text,enabled,mobile_force}
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});