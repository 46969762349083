import request from "@/libs/utils/request";
import {QueryModel} from "@/libs/models";
import parse from '@/libs/utils/QueryParser';
import {front_api} from "@/libs/constants/const";
import {admin_api} from "@/libs/constants/const";

export const getUser = (uid:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}user?uid=${uid}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const getUsers = (group_id:string,query:QueryModel):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${admin_api}users/${group_id}/users${parse(query)}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const saveUsers=(uid:string,name:string,avatar?:string,owner_id?:string,identity?:string,from_source?:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}users`,
        method: 'post',
        data:{
            uid,
            name,
            owner_id,
            avatar,
            identity,
            from_source,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const putUserNnlineState = (uid:string,online:1|0=1):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}user_online_state/${uid}`,
        method: 'put',
        data:{
            online
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const getUserVisitorWidget = (uid:string,tto_clid:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}users_visitor_widget/${uid}?tto_clid=${tto_clid}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const postRegfcm = (group_id,uid:string,token:string,client='web',role='care'):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}groups/${group_id}/regfcm`,
        method: 'post',
        data:{
            uid,
            token,
            client,
            role,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});