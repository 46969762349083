import {QueryModel} from "@/libs/models";
import request from "@/libs/utils/request";
import parse from "@/libs/utils/QueryParser";
import {front_api} from "@/libs/constants/const";

export const whatsappUpload = (messenger_id:string,file:File):Promise<any> => new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file",file);
    formData.append("messenger_id",messenger_id);
    request({
        url: `${front_api}third/whatsapp/upload`,
        method: 'post',
        data:formData
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const whatsappPhones = (business_id:string,token:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `${front_api}third/whatsapp/phones?business_id=${business_id}&token=${token}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});