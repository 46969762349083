import request from "@/libs/utils/request";
import {code_need_pay,code_need_renewal} from "../../config/customCode";
import {front_api} from "@/libs/constants/const";
import {admin_api} from "@/libs/constants/const";

export const openPayPage=(group_id:string,product_type:string):Promise<any> => new Promise((resolve, reject) => {
   request({
        url: `${admin_api}pay?group_id=${group_id}&product_type=${product_type}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const checkCareAsset=(group_id:string,uid:string|number|null=null):Promise<any> => new Promise((resolve, reject) => {
   request({
        url: `${front_api}open-api/check_care_asset`,
        method: 'post',
       data:{
           group_id,
           uid,
       }
    }).then((response: any) => {
       if(response.code===code_need_pay||response.code===code_need_renewal){
           reject(response)
       }
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const getOrders=(group_id:string):Promise<any> => new Promise((resolve, reject) => {
   request({
        url: `${front_api}open-api/asset_quota?group_id=${group_id}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});